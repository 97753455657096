import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import formatTitle from "../../components/common/formatTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";
import IllustrationL from "./mask.png";
import IllustrationR from "./mask_cut.png";
import TitreTexteCta from "../../components/common/TitreTexteCta";
import ArrowButton from "../../components/common/ArrowButton";
import { Button } from "gatsby-material-ui-components";
import City from "./city_short.png";
import Logo1 from "./logo1.jpg";
import Logo2 from "./logo2.png";
import Cyclist from "./cyclist.jpg";
import Team from "./equipe.png";
import Footprint from "./mapmonde2.jpg";
import Passionate1 from "./passionate1.jpg";
import Passionate2 from "./passionate2.png";
import Passionate3 from "./passionate3.jpg";
import Passionate4 from "./passionate4.jpg";
import Passionate5 from "./passionate5.jpg";
import Timeline from "./Timeline";
import TimelineMobile from "./TimelineMobile";
import Award from "./award";
import Net from "./net.svg";
import LinkedIn from "./linkedin.svg";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  firstBgLayout: {
    padding: "5em 10% 10% 10%",
    width: "100%",
    backgroundColor: theme.palette.background.medium,
    backgroundImage: `url(${IllustrationR}), url(${IllustrationL})`,
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "100% 120%, 0% 0%",
    backgroundSize: "auto 80%, auto 80%",
    position: "relative",
  },
  firstBgLayoutLowPad: {
    padding: "10% 10% 10% 10%",
  },
  firstPad: {
    minWidth: "84%",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)",
    borderRadius: 10,
    backgroundImage: `url(${City})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0",
    backgroundSize: "auto 110%",
    padding: theme.spacing(10),
  },
  lowerBlockWrap: {
    position: "absolute",
    backgroundColor: "transparent",
    bottom: -150,
    left: "10%",
    width: "80%",
  },
  lowerBlock: {
    width: "48%",
    backgroundColor: theme.palette.background.default,
    minHeight: 200,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)",
  },
  lowerBlockMobile: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)",
  },
  lowerBlockContent: {
    backgroundColor: theme.palette.background.default,
    width: "84%",
  },
  bolder: {
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  sectionTitle: {
    textAlign: "center",
  },
  name: {
    fontWeight: "bold",
    fontSize: "1.4em",
    marginTop: theme.spacing(1),
  },
  position: {
    color: theme.palette.primary.light,
    fontSize: "1.2em",
    fontWeight: 600,
    lineHeight: "1.1em",
    marginTop: 3,
  },
  bio: {
    margin: 0,
  },
  teamBtn: {
    minWidth: "80%",
  },
  rndEd: {
    borderRadius: 10,
  },
  rndEdLess: {
    borderRadius: 5,
  },
  mBt: {
    marginBottom: theme.spacing(2),
  },
  mBtt: {
    marginBottom: theme.spacing(1),
  },
  mTop: {
    marginTop: theme.spacing(4),
  },
  centered: {
    textAlign: "center",
  },
  link: {
    display: "inline-block",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 18,
  },
  padContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px`,
  },
  lastBlk: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  lastBlkMobile: {
    position: "absolute",
    top: 0,
    left: "10%",
    right: 0,
    bottom: 0,
  },
  fullHeight: {
    height: "100%",
  },
  cardContent: {
    minHeight: 140,
  },
  customPadding: {
    padding: 12,
  },
  small: {
    fontSize: "0.6rem",
  },
}));

const AboutEn = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const contents = data.allAboutJson.nodes[0].about[langKey][0];
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item container direction="row" justify="center">
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={clsx({
            [classes.firstBgLayoutLowPad]: !matches,
            [classes.firstBgLayout]: true,
          })}
        >
          <Grid item className={clsx({ [classes.firstPad]: matches })}>
            <TitreTexteCta
              title={formatTitle(contents.story.title)}
              text={contents.story.content}
              buttonLabel={contents.story.btnLabel}
              ctaUrl={`/${langKey}/contact`}
              btnVariant="text"
              btnColor="primary"
              btnSize="medium"
              lg={6}
              md={12}
              sm={12}
              xs={12}
            />
          </Grid>
          <Grid
            item
            container
            direction={matches ? "row" : "column"}
            justify="space-between"
            className={clsx({ [classes.lowerBlockWrap]: matches })}
          >
            <Award
              image={Logo1}
              title={contents.prices[0].title}
              content={contents.prices[0].content}
              matches={matches}
              imgWidthPrct="70%"
            />
            <Award
              image={Logo2}
              title={contents.prices[1].title}
              content={contents.prices[1].content}
              matches={matches}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        style={{ padding: "18% 10% 1% 10%" }}
      >
        <Grid item>
          <img
            src={Cyclist}
            width="100%"
            alt="cyclist"
            className={classes.rndEd}
          />
        </Grid>
      </Grid>
      {!matchesMobile ? (
        <Timeline datas={contents.timeline} />
      ) : (
        <TimelineMobile datas={contents.timeline} />
      )}
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={clsx({
          [classes.paddingRegular]: matches,
          [classes.paddingRegularMobile]: !matches,
        })}
        spacing={4}
      >
        <Grid item>
          <Typography variant="h1" className={classes.sectionTitle}>
            {formatTitle(contents.team.title)}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={Team}
            alt="team"
            width="100%"
            height="auto"
            className={classes.rndEd}
          />
        </Grid>
        <Grid item className={classes.teamBtn}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            to={`/${langKey}/contact`}
            size="large"
          >
            {contents.team.btnLabel}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        className={clsx({
          [classes.paddingRegular]: matches,
          [classes.paddingRegularMobile]: !matches,
        })}
        spacing={10}
      >
        <Grid item>
          <Typography variant="h1" className={classes.sectionTitle}>
            {formatTitle(contents.footprint.title)}
          </Typography>
        </Grid>
        <Grid item>
          <img src={Footprint} alt="test" width="100%" />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="stretch"
        className={clsx({
          [classes.paddingRegular]: matches,
          [classes.paddingRegularMobile]: !matches,
        })}
        spacing={matches ? 4 : 1}
      >
        <Grid item xs={4}>
          <img
            src={Passionate1}
            width="100%"
            alt="test"
            className={clsx({
              [classes.rndEd]: matches,
              [classes.rndEdLess]: !matches,
            })}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing={matches ? 2 : 1}
          xs={4}
        >
          <Grid item>
            <img
              src={Passionate2}
              alt="test"
              width="100%"
              className={clsx({
                [classes.rndEd]: matches,
                [classes.rndEdLess]: !matches,
              })}
            />
          </Grid>
          <Grid item>
            <img
              src={Passionate4}
              alt="test"
              width="100%"
              className={clsx({
                [classes.rndEd]: matches,
                [classes.rndEdLess]: !matches,
              })}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing={matches ? 2 : 1}
          xs={4}
        >
          <Grid item>
            <img
              src={Passionate3}
              alt="test"
              width="100%"
              className={clsx({
                [classes.rndEd]: matches,
                [classes.rndEdLess]: !matches,
              })}
            />
          </Grid>
          <Grid item style={{ position: "relative" }}>
            <img
              src={Passionate5}
              alt="test"
              width="100%"
              style={{ boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804)" }}
              className={clsx({
                [classes.rndEd]: matches,
                [classes.rndEdLess]: !matches,
              })}
            />
            <div
              className={clsx({
                [classes.lastBlk]: matches,
                [classes.lastBlkMobile]: !matches,
              })}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.fullHeight}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    className={clsx(
                      {
                        [classes.small]: !matches,
                      },
                      classes.bolder,
                      classes.mBtt
                    )}
                  >
                    {formatTitle(contents.passionate.title)}
                  </Typography>
                  {matches && (
                    <ArrowButton
                      label={contents.passionate.btnLabel}
                      to={`/${langKey}/skills`}
                      color="primary"
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AboutEn;

export const query = graphql`
  query AboutQueryEn {
    allAboutJson {
      nodes {
        about {
          en {
            footprint {
              title
            }
            passionate {
              btnLabel
              title
            }
            prices {
              content
              title
            }
            story {
              btnLabel
              content
              title
            }
            team {
              btnLabel
              items {
                content
                position
                name
              }
              title
            }
            timeline {
              title
              items {
                content
                date
                title
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
